import { useBoolean } from "@chakra-ui/hooks";
import { useState } from "react";
import { usePopper as useP } from "react-popper";

const usePopper = (placement = "bottom-end") => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [visible, setVisible] = useBoolean(false);

  const { styles, attributes } = useP(referenceElement, popperElement, {
    placement,
  });

  return {
    setReferenceElement,
    setPopperElement,
    visible,
    setVisible,
    styles,
    attributes,
  };
};

export default usePopper;
