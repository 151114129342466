//@ts-check
import { Box, Button, Divider, HStack, Image, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import DeleteItem from "../../components/admin/DeleteItem";
import FormModal from "../../components/admin/FormModal";

function addMinutes(date, minutes) {
  return new Date(date.getTime() + minutes * 60000);
}
const models = [
  "e4 sultan",
  "sultan",
  "doruk",
  "e6 sultan",
  "kent",
  "atlas",
  "e5 sultan",
  "e4 doruk kent",
  "125 sultan",
  "e6 doruk",
  "tempo",
  "m 2010",
  "e5 atlas",
  "e3 doruk",
];
const Admin = () => {
  const [token, setToken] = useState(null);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoading, data, refetch, isFetched } = useQuery(
    "repoData",
    () =>
      fetch("https://us-central1-somaylar-api.cloudfunctions.net/app/product?queryText=", {
        headers: {
          authorization: "Bearer " + token,
        },
      }).then((res) => res.json()),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token]);

  useEffect(() => {
    const login = async () => {
      var password = window.prompt("Şifre");
      var { token } = await fetch("https://us-central1-somaylar-api.cloudfunctions.net/app/token", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }),
      }).then((x) => x.json());
      if (token) {
        setToken(token);
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("expires", addMinutes(new Date(), 45).toString());
      } else {
        alert("yanlış şifre");
        login();
      }
    };

    const expireDate = window.localStorage.getItem("expires");
    const token = window.localStorage.getItem("token");

    if (expireDate && expireDate && new Date() < new Date(expireDate)) {
      setToken(token);
    } else {
      login();
    }
  }, []);
  const renew = async () => {
    const lastDeploy = window.localStorage.getItem("deploy");
    if (lastDeploy && new Date(lastDeploy) > new Date()) {
      var startTime = new Date();
      var endTime = new Date(lastDeploy);
      var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
      var resultInMinutes = Math.round(difference / 60000);

      toast({
        title: `Bir sonraki güncelleme ${resultInMinutes} dakika sonra yapılabilir.`,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    await fetch("https://api.netlify.com/build_hooks/6162df0996e10cd4f93a9f70", {
      method: "POST",
    });
    toast({
      title: "Site Güncelleniyor",
      description: "30 Dakika kadar sürer.",
      status: "success",
      duration: null,
      isClosable: true,
      position: "top",
    });
    window.localStorage.setItem("deploy", addMinutes(new Date(), 60 * 24).toString());
  };
  if (isLoading) return "Loading...";
  if (isFetched)
    return (
      <Box>
        <FormModal isOpen={isOpen} token={token} onClose={onClose} onSuccess={refetch} />
        <Box bg="white" p="5">
          <HStack justifyContent="flex-end">
            <Button onClick={renew}>Siteyi Güncelle</Button>
            <Button onClick={onOpen}>Yeni Ürün Ekle</Button>
          </HStack>
          <Divider py="5"></Divider>
          <Table>
            <Thead>
              <Tr>
                <Th>GÖRSEL</Th>
                <Th>ÜRÜN ADI</Th>
                <Th>SITE KODU</Th>
                <Th>CODE</Th>
                <Th>MODEL</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.response.map((d) => (
                <Tr key={d.id}>
                  <Td>
                    <Image boxSize="50px" src={d.url}></Image>
                  </Td>
                  <Td>{d.name}</Td>
                  <Td>{d.id.substring(0, 6)}</Td>
                  <Td>{d.code}</Td>
                  <Td>{d.model}</Td>
                  <Td>
                    <DeleteItem onSuccess={refetch} id={d.id} token={token} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>
    );
  return null;
};

export default Admin;
