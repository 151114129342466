//@ts-check
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { CloseIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Center, Divider, Flex, Heading, VStack } from "@chakra-ui/layout";
import {
  Button,
  IconButton,
  Image,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useState } from "react";
import useInput from "../../utils/useInput";
const models = [
  "e4 sultan",
  "sultan",
  "doruk",
  "e6 sultan",
  "kent",
  "atlas",
  "e5 sultan",
  "e4 doruk kent",
  "125 sultan",
  "e6 doruk",
  "tempo",
  "m 2010",
  "e5 atlas",
  "e3 doruk",
];
const FormModal = ({ onClose, onSuccess, token, isOpen }) => {
  const name = useInput();
  const code = useInput();
  const model = useInput();
  const [price, setPrice] = useState(0);
  const [picture, setPicture] = useState(null);
  const hiddenInput = useRef(null);
  const [isActive, setActive] = useBoolean(false);
  const url = useMemo(() => (picture ? URL.createObjectURL(picture) : ""), [picture]);
  const toast = useToast();
  const addNew = async () => {
    setActive.on();
    var form = new FormData();

    form.append("file", picture, name.value.trim().toLocaleLowerCase("tr") + ".png");
    form.append("code", code.value);
    form.append("name", name.value);
    form.append("model", model.value.toLowerCase());
    form.append("price", price.toString());

    await fetch("https://us-central1-somaylar-api.cloudfunctions.net/app/product", {
      method: "POST",
      body: form,
      headers: {
        authorization: "Bearer " + token,
      },
    });

    await onSuccess();
    toast({
      title: "Ürün Eklendi.",
      description: name.value,
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
    setPicture(null);
    setPrice(0);
    model.reset();
    code.reset();
    name.reset();
    onClose();
    setActive.off();
  };
  if (isOpen)
    return (
      <Center bg="blackAlpha.400" position="fixed" inset="0" zIndex="modal">
        <VStack spacing="20px" p="30px" bg="white" borderRadius="10px" w="100%" maxW="500px">
          <Flex w="100%" mb="15px" justifyContent="space-between" alignItems="center">
            <Heading fontSize="23px" fontWeight="bold">
              Yeni Ürün Ekle
            </Heading>
            <IconButton size="sm" aria-label="Close" icon={<CloseIcon />} onClick={onClose} />
          </Flex>
          <FormControl>
            <FormLabel>Ürün İsmi</FormLabel>
            <Input focusBorderColor="#a8533d" value={name.value} onChange={name.handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Ürün Kodu</FormLabel>
            <Input focusBorderColor="#a8533d" value={code.value} onChange={code.handleChange} />
          </FormControl>
          <FormControl>
            <FormLabel>Model</FormLabel>

            <Select focusBorderColor="#a8533d" placeholder="Select option" value={model.value} onChange={model.handleChange}>
              {models.map((d) => (
                <option key={d} value={d}>
                  {d}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Ürün Fiyatı</FormLabel>
            <NumberInput focusBorderColor="#a8533d" value={price} onChange={(e) => setPrice(Number(e))}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
          <FormControl>
            <FormLabel>Ürün Görseli</FormLabel>
            {picture && (
              <Image objectFit="contain" boxSize="75px" mb="10px" onClick={() => hiddenInput.current?.click()} src={url}></Image>
            )}

            {!!!picture && <Button onClick={() => hiddenInput.current?.click()}>Ekle</Button>}
            <input hidden type="file" ref={hiddenInput} onChange={(e) => setPicture(e.target.files[0])} />
          </FormControl>
          <Divider></Divider>
          <Button isLoading={isActive} onClick={addNew} w="100%">
            Kaydet
          </Button>
        </VStack>
      </Center>
    );
  return null;
};

export default FormModal;
