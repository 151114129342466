//@ts-check
import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, IconButton, useBoolean, useToast, VStack } from "@chakra-ui/react";
import React from "react";
import usePopper from "../../utils/usePopper";
const DeleteItem = ({ onSuccess, id, token }) => {
  const popper = usePopper();
  const [isActive, setActive] = useBoolean(false);
  const toast = useToast();
  const deleteItem = async () => {
    setActive.on();
    await fetch(`https://us-central1-somaylar-api.cloudfunctions.net/app/product/${id}`, {
      method: "DELETE",
      headers: {
        authorization: "Bearer " + token,
      },
    });

    await onSuccess();
    toast({
      title: "Ürün silindi.",
      status: "success",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
    setActive.off();
    popper.setVisible.off();
  };

  return (
    <Box>
      <IconButton ref={popper.setReferenceElement} onClick={popper.setVisible.toggle} aria-label="Delete" icon={<DeleteIcon />} />
      {popper.visible && (
        <VStack
          spacing="20px"
          p="14px"
          bg="white"
          borderRadius="lg"
          zIndex="modal"
          alignItems="stretch"
          ref={popper.setPopperElement}
          style={popper.styles.popper}
          {...popper.attributes.popper}
        >
          <Box fontSize="16px" fontWeight="bold">
            Bu işlem geri alınamaz. Onaylıyor musunuz?
          </Box>
          <Button isLoading={isActive} onClick={deleteItem}>
            Onay
          </Button>
        </VStack>
      )}
      {popper.visible && (
        <Box onClick={isActive ? null : popper.setVisible.off} position="fixed" bg="blackAlpha.400" inset="0" zIndex="overlay" />
      )}
    </Box>
  );
};

export default DeleteItem;
